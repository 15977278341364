@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.promotion-container {
  @media screen and (min-width: 1920px) {
    padding: 0 3%;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-heading {
      justify-content: flex-end;
    }

    .arrow-container {
      justify-self: flex-end;

      .arrow {
        background-color: @Gray10;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.8;

        &.disabled {
          opacity: 0.4;
          cursor: auto;
        }
      }
    }
  }

  .promotion,
  .top-section {
    padding: 0 @spacer6;

    @media screen and (min-width: @screen-lg) {
      padding: 0 3%;
    }

    @media screen and (min-width: 1920px) {
      padding: 0;
    }
  }

  .promotion {
    cursor: pointer;
    position: relative;
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
      height: 0.3rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: @Gray90;
      border-radius: 0.6rem;
    }

    &::-webkit-scrollbar-track {
      background-color: @Gray10;
      margin: 0 @spacer6;

      @media screen and (min-width: @screen-lg) {
        margin: 0 3vw;
      }

      @media screen and (min-width: 1920px) {
        margin: 0;
      }
    }

    .productSliderItem {
      width: 240px;
      min-width: 240px;
      margin: 0 @spacer3;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @media screen and (min-width: @screen-lg) {
        width: 300px;
        min-width: 300px;
      }

      .image-container {
        min-height: 300px;
        position: relative;

        @media screen and (min-width: @screen-lg) {
          min-height: 376px;
        }

        img {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .description {
        color: @Gray80;
      }

      .price {
        color: @Gray80;

        &.card-spacing {
          margin-bottom: @spacer7;

          @media screen and (min-width: @screen-lg) {
            margin-bottom: @spacer8;
          }
        }
      }
      .price-value {
        font-size: 1.6rem;
      }
    }
  }
}

.center {
  @media (min-width: @screen-lg) {
    .top-section {
      justify-content: center;
    }

    .arrow-container {
      display: none !important;
    }

    .promotion {
      justify-content: center;
    }
  }
}

.thule-site:not(.caselogic-site) {
  .productSliderItem {
    .image-container {
      background-color: @Gray10;
    }
  }
}

.article-page {
  .promotion-container {
    @media (min-width: @screen-lg-min) {
      padding-left: calc(3%);
      padding-right: calc(3%);
    }

    .top-section {
      display: none;
    }

    .promotion {
      @media (min-width: @screen-xs-min) {
        overflow-x: auto;

        &:not(.single-product) {
          display: grid;
          gap: @spacer5;
          grid-template-columns: repeat(3, 1fr);
        }
      }

      @media (min-width: @screen-lg-min) {
        margin-left: 8.33333333%;
        padding: 0;
        width: 83.33333333%;
        cursor: pointer !important;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: @spacer6;
      }

      @media (min-width: @screen-xl-min) {
        width: 66.66666667%;
        margin-left: 16.66666667%;
      }

      &::-webkit-scrollbar-track {
        @media screen and (min-width: @screen-lg) {
          margin: 0;
        }
      }

      &.single-product {
        .productSliderItem {
          @media screen and (max-width: @screen-lg) {
            width: 100%;
            max-width: 390px;
          }
        }
      }

      .productSliderItem {
        width: unset;
        margin: 0;

        @media screen and (min-width: @screen-lg) {
          width: unset;
          min-width: unset;
        }
      }

      .productSliderItem__contentContainer {
        text-align: center;
      }

      .image-container {
        background-color: @White;
      }

      .price {
        display: none;
      }
    }
  }
}
